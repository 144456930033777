exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-elpado-js": () => import("./../../../src/pages/projects/elpado.js" /* webpackChunkName: "component---src-pages-projects-elpado-js" */),
  "component---src-pages-projects-internett-js": () => import("./../../../src/pages/projects/internett.js" /* webpackChunkName: "component---src-pages-projects-internett-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-oprawamuzyczna-js": () => import("./../../../src/pages/projects/oprawamuzyczna.js" /* webpackChunkName: "component---src-pages-projects-oprawamuzyczna-js" */),
  "component---src-pages-projects-topevent-js": () => import("./../../../src/pages/projects/topevent.js" /* webpackChunkName: "component---src-pages-projects-topevent-js" */),
  "component---src-pages-tech-js": () => import("./../../../src/pages/tech.js" /* webpackChunkName: "component---src-pages-tech-js" */)
}

