import React from 'react'
import styled from 'styled-components'
import Icon from './Icon';

const Svg = styled(Icon)` 

`;

const EmailIcon = ({ className, hovered, color }) => ( 
  <Svg  width="33.775" height="33.6" viewBox="0 0 33.775 33.6" className={className} fill={color}>
    <path id="Path_27" data-name="Path 27" d="M18.48-25.55A16.667,16.667,0,0,0,1.575-8.68,16.488,16.488,0,0,0,18.48,8.05a15.224,15.224,0,0,0,5.46-.945l-1.155-3.15a14.141,14.141,0,0,1-4.305.63A13.01,13.01,0,0,1,5.075-8.68a13.188,13.188,0,0,1,13.4-13.405c7.735,0,13.65,5.46,13.65,13.405,0,4.76-2.275,7.035-4.585,7.035-2.2,0-3.185-2-2.695-4.34l1.96-9.17H23.17l-.6,2.275a4.537,4.537,0,0,0-4.55-2.765c-3.325,0-6.2,2.31-7.035,6.265-.84,3.92,1.68,7.84,5.845,7.84a4.78,4.78,0,0,0,4.2-2.17,5.844,5.844,0,0,0,5.88,5.285c5.11,0,8.435-4.445,8.435-10.255A16.449,16.449,0,0,0,18.48-25.55Zm-.7,20.405a3.092,3.092,0,0,1-3.01-3.85,3.692,3.692,0,0,1,3.675-3.045,3.089,3.089,0,0,1,3.01,3.85A3.666,3.666,0,0,1,17.78-5.145Z" transform="translate(-1.575 25.55)" />
  </Svg>
);

export default EmailIcon