import React from 'react'
import styled from 'styled-components'
import Icon from './Icon';

const Svg = styled(Icon)` 

`;

const Behance = ({ className, hovered, color }) => ( 
  <Svg  width="35.252" height="22.1" viewBox="0 0 35.252 22.1" className={className}  fill={color}>
    <path 
        id="Icon_awesome-behance" data-name="Icon awesome-behance" d="M14.2,15.2a4.644,4.644,0,0,0,2.962-4.529c0-4.321-3.219-5.373-6.934-5.373H0v21.69H10.514c3.941,0,7.644-1.891,7.644-6.3A5.226,5.226,0,0,0,14.2,15.2ZM4.768,9H9.241c1.72,0,3.268.483,3.268,2.479,0,1.842-1.206,2.583-2.907,2.583H4.768V9Zm5.1,14.3h-5.1V17.334h5.2c2.1,0,3.427.875,3.427,3.1,0,2.191-1.585,2.876-3.525,2.876ZM31.806,8.576H23.011V6.44h8.795Zm3.446,10.79c0-4.645-2.717-8.519-7.644-8.519-4.786,0-8.036,3.6-8.036,8.311,0,4.89,3.078,8.244,8.036,8.244,3.752,0,6.181-1.689,7.35-5.282H31.151c-.41,1.34-2.1,2.05-3.409,2.05-2.528,0-3.856-1.481-3.856-4H35.215C35.233,19.916,35.252,19.641,35.252,19.365ZM23.893,17.456a3.371,3.371,0,0,1,3.58-3.354c2.167,0,3.256,1.273,3.439,3.354Z" transform="translate(0 -5.302)"
    />
  </Svg>
);

export default Behance