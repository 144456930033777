import React from 'react'
import styled from 'styled-components'
import Icon from './Icon';

const Svg = styled(Icon)` 

`;

const Instagram = ({ className, hovered, color }) => ( 
  <Svg width="35.085" height="35.077" viewBox="0 0 35.085 35.077" className={className}         fill={color}>   
    <path 
        id="Icon_awesome-instagram" data-name="Icon awesome-instagram" d="M17.541,10.783a8.993,8.993,0,1,0,8.993,8.993A8.979,8.979,0,0,0,17.541,10.783Zm0,14.84a5.847,5.847,0,1,1,5.847-5.847A5.857,5.857,0,0,1,17.541,25.623ZM29,10.415a2.1,2.1,0,1,1-2.1-2.1A2.093,2.093,0,0,1,29,10.415Zm5.956,2.129c-.133-2.81-.775-5.3-2.833-7.35s-4.54-2.692-7.35-2.833C21.877,2.2,13.2,2.2,10.3,2.361c-2.8.133-5.291.775-7.35,2.826S.259,9.726.118,12.536c-.164,2.9-.164,11.576,0,14.472.133,2.81.775,5.3,2.833,7.35s4.54,2.692,7.35,2.833c2.9.164,11.576.164,14.472,0,2.81-.133,5.3-.775,7.35-2.833s2.692-4.54,2.833-7.35c.164-2.9.164-11.568,0-14.464ZM31.215,30.116A5.919,5.919,0,0,1,27.88,33.45c-2.309.916-7.788.7-10.339.7s-8.038.2-10.339-.7a5.919,5.919,0,0,1-3.334-3.334c-.916-2.309-.7-7.788-.7-10.339s-.2-8.038.7-10.339A5.919,5.919,0,0,1,7.2,6.1c2.309-.916,7.788-.7,10.339-.7s8.038-.2,10.339.7a5.919,5.919,0,0,1,3.334,3.334c.916,2.309.7,7.788.7,10.339S32.131,27.814,31.215,30.116Z" transform="translate(0.005 -2.238)"
        />
  </Svg>
);

export default Instagram